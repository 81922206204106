import { createApp } from 'vue'
import App from './App.vue'
import i18n from './locales/i18n'
import routes from './routes/routes'
import { VueReCaptcha } from 'vue-recaptcha-v3'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fab } from '@fortawesome/free-brands-svg-icons'
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

import { fas } from '@fortawesome/free-solid-svg-icons'

import VueEasyLightbox from 'vue-easy-lightbox'

/* add icons to the library */
library.add(fas,fab);

createApp(App)
    .use(i18n)
    .use(VueEasyLightbox)
    .use(routes)
    .use(VueReCaptcha, { siteKey: '6LesNI8qAAAAAC7yUJnDvEDlg-rrzkRItV9x5cPb' })
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');
